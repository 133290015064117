import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconVerified from '../../../components/IconVerified/IconVerified';
import { userIsVerified } from '../../../util/user';
import { propTypes } from '../../../util/types';
import css from './UserCard.module.css';

const UserVerifyStatus = ({ user }) => {
  const isVerified = userIsVerified(user);
  if (!isVerified) {
    return null;
  }
  return (
    <div className={css.verifySection}>
      <IconVerified width={20} height={20} />
      <span className={css.verified}>
        <FormattedMessage ignoreTag id="UserCard.verifiedStatus" />
      </span>
    </div>
  );
};

UserVerifyStatus.propTypes = {
  user: propTypes.user.isRequired,
};

export default UserVerifyStatus;
