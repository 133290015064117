import { string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import css from './ListingSize.module.css';
import { allSizeOptions } from '../../../config/configListingOptions';

const ListingSize = ({ size, className, rootClassName }) => {
  const selectedSize = allSizeOptions.find(option => option.option === size);

  if (!selectedSize) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <FormattedMessage
        id="ListingPage.size"
        values={{
          size: selectedSize.label,
        }}
      />
    </div>
  );
};

ListingSize.defaultProps = {
  className: null,
  rootClassName: null,
  size: null,
};

ListingSize.propTypes = {
  className: string,
  rootClassName: string,
  size: string,
};

export default ListingSize;
