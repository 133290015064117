import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import { bool, func } from 'prop-types';
import classNames from 'classnames';
import css from './MakeAnOfferPopup.module.css';
import { useDisclose } from '../../util/hooks';
import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { getPriceValidators } from '../../util/validators';
import { Form, Modal, PrimaryButton, FieldCurrencyInput, SecondaryButton } from '..';
import { propTypes } from '../../util/types';

const MakeAnOfferPopup = forwardRef((props, ref) => {
  const {
    handleMakeAnOffer,
    onManageDisableScrolling,
    makeAnOfferInProgress,
    makeAnOfferError,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclose();
  const [changeContent, setChangeContent] = useState(false);
  const intl = useIntl();
  const config = useConfiguration();
  useImperativeHandle(
    ref,
    () => ({
      onOpen,
      onClose,
    }),
    [onClose, onOpen]
  );
  const marketplaceCurrency = config.currency;

  const currencyConfig = appSettings.getCurrencyFormatting(marketplaceCurrency);

  const priceRequired = getPriceValidators(100, marketplaceCurrency, intl);
  if (!isOpen) {
    return null;
  }

  const handleOnClose = () => {
    onClose();
    setChangeContent(false);
  };
  return (
    <Modal
      id="MakeAnOfferModal"
      isOpen={isOpen}
      onClose={() => {
        handleOnClose();
      }}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div>
        <h3>
          <FormattedMessage id="MakeAnOfferModal.title" />
        </h3>
        <FinalForm
          onSubmit={handleMakeAnOffer}
          render={({ handleSubmit, invalid }) => {
            const disabled = invalid || makeAnOfferInProgress;

            return (
              <Form onSubmit={handleSubmit}>
                {changeContent ? (
                  <>
                    <div className={css.confirmText}>
                      <FormattedMessage id="MakeAnOfferPopup.confirmOffer" />
                    </div>

                    <div className={css.btnWrapper}>
                      <PrimaryButton
                        className={classNames(css.submitButton, css.yesButton)}
                        inProgressClassName={css.inProgress}
                        disabled={disabled}
                        inProgress={makeAnOfferInProgress}
                        type="submit"
                      >
                        <FormattedMessage id="MakeAnOfferPopup.yes" />
                      </PrimaryButton>
                      <SecondaryButton
                        className={css.noButton}
                        disabled={disabled}
                        type="button"
                        onClick={() => {
                          handleOnClose();
                        }}
                      >
                        <FormattedMessage id="MakeAnOfferPopup.no" />
                      </SecondaryButton>
                    </div>
                  </>
                ) : (
                  <>
                    <FieldCurrencyInput
                      id="FieldCurrencyInput.price"
                      name="totalPrice"
                      label={intl.formatMessage({ id: 'MakeAnOfferModal.totalPriceLabel' })}
                      placeholder={intl.formatMessage({
                        id: 'MakeAnOfferModal.totalPricePlacholder',
                      })}
                      currencyConfig={currencyConfig}
                      validate={priceRequired}
                      className={css.input}
                    />
                    {makeAnOfferError ? (
                      <p className={css.error}>
                        <FormattedMessage id="MakeAnOfferModal.errorMessage" />
                      </p>
                    ) : null}
                    <PrimaryButton
                      className={css.submitButton}
                      inProgressClassName={css.inProgress}
                      disabled={disabled}
                      inProgress={makeAnOfferInProgress}
                      type="button"
                      onClick={() => {
                        setChangeContent(true);
                      }}
                    >
                      <FormattedMessage id="MakeAnOfferModal.title" />
                    </PrimaryButton>
                  </>
                )}
              </Form>
            );
          }}
        />
      </div>
    </Modal>
  );
});

export default MakeAnOfferPopup;

MakeAnOfferPopup.defaultProps = {
  makeAnOfferError: null,
};

MakeAnOfferPopup.propTypes = {
  onManageDisableScrolling: func.isRequired,
  handleMakeAnOffer: func.isRequired,
  makeAnOfferInProgress: bool.isRequired,
  makeAnOfferError: propTypes.error,
};
