import { string } from 'prop-types';
import classNames from 'classnames';
import React from 'react';

import { useIntl } from 'react-intl';
import css from './ListingPrice.module.css';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';

const ListingPrice = ({ price, className, rootClassName }) => {
  const intl = useIntl();
  if (!price) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);

  const formattedMoney = formatMoney(intl, price, { minimumFractionDigits: 0 });

  return <div className={classes}>{formattedMoney}</div>;
};

ListingPrice.defaultProps = {
  className: null,
  rootClassName: null,
  price: null,
};

ListingPrice.propTypes = {
  className: string,
  price: propTypes.money,
  rootClassName: string,
};

export default ListingPrice;
