import { number, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import css from './ListingLikes.module.css';
import IconHeart from '../../../components/IconHeart/IconHeart';
import { userHasLikedListing } from '../../../util/user';
import { propTypes } from '../../../util/types';

import { likeListing } from '../ListingPage.duck';
import { useConfiguration } from '../../../context/configurationContext';

const ListingLikes = ({ likes, className, listingId, currentUser }) => {
  const intl = useIntl();
  const config = useConfiguration();
  const classes = classNames(css.root, className);
  const hasLikes = likes > 0;

  const dispatch = useDispatch();

  const isLiked = userHasLikedListing(currentUser, listingId.uuid);

  const disabled = !currentUser?.id;

  const handleLike = () => {
    if (disabled) {
      return;
    }

    dispatch(
      likeListing({
        listingId,
        config,
        isLiked,
      })
    );
  };

  const formattedLikes = intl.formatNumber(likes, { notation: 'compact' });
  return (
    <div className={classes}>
      {hasLikes && <div className={css.likes}>{formattedLikes}</div>}
      <IconHeart selected={isLiked} fill="red" onClick={handleLike} className={css.iconHeart} />
    </div>
  );
};

ListingLikes.defaultProps = {
  className: null,
  likes: 0,
  currentUser: null,
};

ListingLikes.propTypes = {
  className: string,
  likes: number,
  listingId: propTypes.uuid.isRequired,
  currentUser: propTypes.currentUser,
};

export default ListingLikes;
