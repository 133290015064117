import { arrayOf, string } from 'prop-types';
import React from 'react';
import ListingImageGallery from '../ListingImageGallery/ListingImageGallery';

import css from './SectionGallery.module.css';
import { propTypes } from '../../../util/types';

const SectionGallery = ({ images, variantPrefix }) => {
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <div className={css.root} data-testid="carousel">
      <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
    </div>
  );
};

SectionGallery.defaultProps = {
  images: [],
};

SectionGallery.propTypes = {
  images: arrayOf(propTypes.image),
  variantPrefix: string.isRequired,
};

export default SectionGallery;
