import get from 'lodash/get';

// eslint-disable-next-line import/prefer-default-export
export const getNumberOfLikes = listing => {
  return get(listing, 'attributes.metadata.likes', 0);
};

export const isListingAuthor = (listing, user) => {
  return user?.id?.uuid === listing?.author?.id?.uuid;
};

export const getUserAddressBook = currentUser => {
  return get(currentUser, 'attributes.profile.privateData.addresses', []);
};
