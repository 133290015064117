import { arrayOf, node, object, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { Heading } from '../../../components';

import { categories, subCategories, types } from '../../../config/configListing';

import css from './SectionDetailsMaybe.module.css';
import { propTypes } from '../../../util/types';

const DetailsGrid = ({ children }) => {
  return <div className={css.detailsGrid}>{children}</div>;
};

DetailsGrid.propTypes = {
  children: oneOfType([object, arrayOf(node)]).isRequired,
};

const Detail = ({ label, value }) => {
  return (
    <>
      <div className={css.detailLabel}>{label}</div>
      <div className={css.detailValue}>{value}</div>
    </>
  );
};

Detail.propTypes = {
  label: oneOfType([string, node]).isRequired,
  value: oneOfType([string, node]).isRequired,
};

const pickListingFields = ({ publicData, metadata, intl }) => (filteredConfigs, config) => {
  const { key, schemaType, enumOptions, showConfig = {} } = config;
  const { isDetail, label } = showConfig;
  const publicDataValue = publicData[key];
  const metadataValue = metadata[key];
  const value = publicDataValue || metadataValue;

  if (isDetail && value && value.length) {
    const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
    const getBooleanMessage = booleanValue =>
      booleanValue
        ? intl.formatMessage({ id: 'SearchPage.detailYes' })
        : intl.formatMessage({ id: 'SearchPage.detailNo' });
    const optionConfig = findSelectedOption(value);

    const getMultiEnumValueLabels = (enumValues = []) =>
      enumValues.map(enumValue => findSelectedOption(enumValue)?.label || enumValue).join(', ');

    switch (schemaType) {
      case 'enum':
        return filteredConfigs.concat({ key, value: optionConfig?.label, label });
      case 'boolean':
        return filteredConfigs.concat({ key, value: getBooleanMessage(value), label });
      case 'long':
        return filteredConfigs.concat({ key, value, label });
      case 'multi-enum':
        return filteredConfigs.concat({ key, value: getMultiEnumValueLabels(value), label });
      default:
        return filteredConfigs.concat({ key, value, label });
    }
  }
  return filteredConfigs;
};

const SectionDetailsMaybe = ({
  category,
  publicData,
  metadata,
  listingConfig,
  subCategory,
  className,
  type,
}) => {
  const intl = useIntl();

  const pickListingFieldsFn = pickListingFields({
    publicData,
    metadata,
    intl,
  });

  const selectedCategory = categories.enumOptions.find(c => c.option === category);
  const subCategoriesOptions = subCategories.enumSections[category] || [];
  const { listingFields } = listingConfig;
  const existingListingFields = listingFields.reduce(pickListingFieldsFn, []);

  const selectedSubCategory = subCategoriesOptions.find(c => c.option === subCategory);

  const selectedTypeOptions = types.enumSections[category]?.[subCategory] ?? [];
  const selectedType = selectedTypeOptions.find(t => t.option === type);

  return (
    <div className={classNames(css.sectionDetails, className)}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" ignoreTag />
      </Heading>
      <DetailsGrid>
        {selectedCategory && selectedSubCategory ? (
          <Detail
            label={intl.formatMessage({ id: 'ListingPage.category' })}
            value={`${selectedCategory.label}, ${selectedSubCategory.label}`}
          />
        ) : null}
        {selectedType ? (
          <Detail
            label={intl.formatMessage({ id: 'ListingPage.type' })}
            value={selectedType.label}
          />
        ) : null}
        {existingListingFields.map(({ key, value, label }) => (
          <Detail key={key} label={label} value={value} />
        ))}
      </DetailsGrid>
    </div>
  );
};

SectionDetailsMaybe.defaultProps = {
  category: null,
  subCategory: null,
  publicData: {},
  metadata: {},
  className: null,
  type: null,
};

SectionDetailsMaybe.propTypes = {
  category: string,
  listingConfig: shape({
    listingFields: propTypes.listingFieldsConfig.isRequired,
  }).isRequired,
  metadata: object,
  publicData: object,
  subCategory: string,
  className: string,
  type: string,
};

export default SectionDetailsMaybe;
