import { number } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const UserStats = ({ listedListings, soldListings }) => {
  return (
    <span>
      <FormattedMessage id="UserCard.numberOfListed" values={{ listedListings }} />{' '}
      <FormattedMessage id="UserCard.numberOfSoldListings" values={{ soldListings }} />
    </span>
  );
};

UserStats.defaultProps = {
  listedListings: 0,
  soldListings: 0,
};

UserStats.propTypes = {
  listedListings: number,
  soldListings: number,
};

export default UserStats;
