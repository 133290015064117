import { string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './SectionBuyerProtection.module.css';
import { Heading, NamedLink } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';

const SectionBuyerProtection = ({ className }) => {
  const config = useConfiguration();
  return (
    <div className={className}>
      <div className={css.root}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage
            id="ListingPage.buyerProtectionTitle"
            ignoreTag
            values={{ marketplaceName: config.marketplaceName }}
          />
        </Heading>
        <span className={css.text}>
          <FormattedMessage
            id="ListingPage.buyerProtectionText"
            values={{
              learnMore: (
                <NamedLink name="PrivacyPolicyPage">
                  <FormattedMessage id="ListingPage.buyerProtectionLearnMore" />
                </NamedLink>
              ),
            }}
          />
        </span>
      </div>
    </div>
  );
};

SectionBuyerProtection.defaultProps = {
  className: null,
};

SectionBuyerProtection.propTypes = {
  className: string,
};

export default SectionBuyerProtection;
