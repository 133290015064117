import React, { useEffect, useState } from 'react';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import IconShare from '../../../components/IconShare/IconShare';
import { Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';

import css from './ListingShareButton.module.css';

const ListingShareButton = () => {
  const config = useConfiguration();
  const [pageUrl, setPageUrl] = useState('');
  useEffect(() => {
    setPageUrl(window.location.href);
  }, []);
  return (
    <div className={css.root}>
      <Menu>
        <MenuLabel>
          <IconShare width="18px" height="18px" />
        </MenuLabel>
        <MenuContent className={css.menuContent}>
          <MenuItem key="Email">
            <EmailShareButton url={pageUrl} subject="" separator="">
              Email
            </EmailShareButton>
          </MenuItem>
          <MenuItem key="Facebook">
            <FacebookShareButton url={pageUrl}>Facebook</FacebookShareButton>
          </MenuItem>
          <MenuItem key="Messenger">
            <FacebookMessengerShareButton url={pageUrl} appId={config.facebookAppId}>
              Messenger
            </FacebookMessengerShareButton>
          </MenuItem>
          <MenuItem key="Twitter">
            <TwitterShareButton url={pageUrl} title="">
              Twitter
            </TwitterShareButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

export default ListingShareButton;
