import { arrayOf } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReviewRating } from '../../../components';
import { propTypes } from '../../../util/types';
import css from './UserCard.module.css';

const averageReviews = reviews => {
  const sum = reviews.reduce((acc, review) => {
    return acc + review.attributes.rating;
  }, 0);

  return sum / (reviews.length || 1.0);
};

const UserReviews = ({ reviews }) => {
  const average = averageReviews(reviews);

  return (
    <span>
      {average > 0 ? (
        <ReviewRating
          reviewStarClassName={css.star}
          rating={average}
          starWidth="18px"
          starHeight="18px"
        />
      ) : null}{' '}
      <FormattedMessage
        id="UserCard.numberOfReviews"
        values={{
          numberOfReviews: reviews.length,
        }}
      />
    </span>
  );
};

UserReviews.defaultProps = {
  reviews: [],
};

UserReviews.propTypes = {
  reviews: arrayOf(propTypes.review),
};

export default UserReviews;
