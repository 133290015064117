import { string } from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import css from './SectionShipping.module.css';
import { Heading } from '../../../components';

const SectionShipping = ({ className }) => {
  const classes = classNames(css.root, className);
  return (
    <div className={classes}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.shippingTitle" ignoreTag />
      </Heading>
    </div>
  );
};

SectionShipping.defaultProps = {
  className: null,
};

SectionShipping.propTypes = {
  className: string,
};

export default SectionShipping;
