import { bool, func } from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { NamedLink, PrimaryButton, SecondaryButton } from '../../../components';

import css from './ActionButtons.module.css';
import { useConfiguration } from '../../../context/configurationContext';

const ActionButtons = ({ isOutOfStock, isClosed, onMakeAnOffer, onBuyNow, isOwnListing }) => {
  const config = useConfiguration();
  const intl = useIntl();

  if (isClosed) {
    return (
      <div className={css.root}>
        <FormattedMessage id="OrderPanel.closedListingButtonText" />
      </div>
    );
  }

  if (isOutOfStock) {
    return (
      <div className={css.root}>
        <PrimaryButton disabled>
          <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
        </PrimaryButton>
      </div>
    );
  }

  if (isOwnListing) {
    return (
      <div className={css.root}>
        <PrimaryButton disabled>
          <FormattedMessage id="BookingDatesForm.ownListing" />
        </PrimaryButton>
      </div>
    );
  }
  return (
    <div className={css.root}>
      <div className={css.buttons}>
        <PrimaryButton className={classNames(css.button, css.buyNow)} onClick={onBuyNow}>
          {intl.formatMessage({
            id: 'ActionButtons.buyNow',
          })}
        </PrimaryButton>
        <SecondaryButton className={css.button} onClick={onMakeAnOffer}>
          {intl.formatMessage({
            id: 'ActionButtons.addToCart',
          })}
        </SecondaryButton>
      </div>
      <span className={css.notes}>
        <FormattedMessage
          id="ActionButtons.notes"
          values={{
            marketplaceName: config.marketplaceName,
            privacyPolicy: (
              <NamedLink name="PrivacyPolicyPage">
                {intl.formatMessage({
                  id: 'ActionButtons.privacyPolicy',
                })}
              </NamedLink>
            ),
            termsOfService: (
              <NamedLink name="TermsOfServicePage">
                {intl.formatMessage({
                  id: 'ActionButtons.termsOfService',
                })}
              </NamedLink>
            ),
          }}
        />
      </span>
    </div>
  );
};

ActionButtons.propTypes = {
  isClosed: bool.isRequired,
  isOutOfStock: bool.isRequired,
  onMakeAnOffer: func.isRequired,
  onBuyNow: func.isRequired,
  isOwnListing: bool.isRequired,
};

export default ActionButtons;
