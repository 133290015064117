import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import css from './SectionSecurePaymentOptions.module.css';
import { Heading, IconCard } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';

const SectionSecurePaymentOptions = ({ className }) => {
  const config = useConfiguration();
  const classes = classNames(css.root, className);
  return (
    <div className={classes}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.securePaymentTitle" ignoreTag />
      </Heading>
      <div className={css.cards}>
        <IconCard brand="visa" />
        <IconCard brand="mastercard" />
        <IconCard brand="american_express" />
      </div>
      <div className={css.notes}>
        <FormattedMessage
          id="SectionSecurePaymentOptions.notes"
          values={{
            marketplaceName: config.marketplaceName,
          }}
        />
      </div>
    </div>
  );
};

export default SectionSecurePaymentOptions;
