import React from 'react';
import { arrayOf, bool, func, number, oneOfType, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { ensureUser, ensureCurrentUser } from '../../../util/data';
import { propTypes } from '../../../util/types';

import { NamedLink, InlineTextButton, AvatarMedium } from '../../../components';

import css from './UserCard.module.css';
import UserReviews from './UserReviews';
import UserStats from './UserStats';
import UserVerifyStatus from './UserVerifyStatus';

const UserCard = props => {
  const {
    rootClassName,
    className,
    user,
    currentUser,
    onContactUser,
    showContact,
    reviews,
    listedListings,
    soldListings,
  } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName } = ensuredUser.attributes.profile;

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const classes = classNames(rootClassName || css.root, className);

  const contact = showContact ? (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </InlineTextButton>
  ) : null;

  const links = ensuredUser.id ? (
    <p className={css.links}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
        <FormattedMessage id="UserCard.viewProfileLink" />
      </NamedLink>
      {!isCurrentUser && contact}
    </p>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.content}>
        <AvatarMedium className={css.avatar} user={user} />
        <div className={css.info}>
          <FormattedMessage id="UserCard.heading" values={{ name: displayName }} />
          <div>
            <UserReviews reviews={reviews} />
            {' | '}
            <UserStats listedListings={listedListings} soldListings={soldListings} />
          </div>
          <UserVerifyStatus user={ensuredUser} />
        </div>
      </div>
      {links}
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
  showContact: true,
  reviews: [],
  listedListings: 0,
  soldListings: 0,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
  showContact: bool,
  reviews: arrayOf(propTypes.review),
  listedListings: number,
  soldListings: number,
};

export default UserCard;
