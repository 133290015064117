import { useCallback, useEffect, useState } from 'react';

export default function useMediaQuery(query, matchedValue, defaultValue) {
  const getMatches = queryString => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(queryString).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  const handleChange = useCallback(() => {
    setMatches(getMatches(query));
  }, [query]);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    handleChange();
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [handleChange, query]);

  if (matches) {
    return matchedValue;
  }

  return defaultValue;
}
