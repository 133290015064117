import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, bool, func, shape, string, oneOf, object, number } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H4, Page, NamedRedirect, LayoutSingleColumn } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  makeAnOffer,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe/SectionDetailsMaybe';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionGallery from './SectionGallery/SectionGallery';

import ListingPrice from './ListingPrice/ListingPrice';
import ListingSize from './ListingSize/ListingSize';
import ListingLikes from './ListingLikes/ListingLikes';
import { getNumberOfLikes } from '../../util/listing';
import SectionShipping from './SectionShipping/SectionShipping';
import SectionSecurePaymentOptions from './SectionSecurePaymentOptions/SectionSecurePaymentOptions';
import SectionBuyerProtection from './SectionBuyerProtection/SectionBuyerProtection';
import ActionButtons from './ActionButtons/ActionButtons';
import css from './ListingPage.module.css';
import ListingShareButton from './ListingShareButton/ListingShareButton';
import MakeAnOfferPopup from '../../components/MakeAnOfferPopup/MakeAnOfferPopup';
import { createResourceLocatorString } from '../../util/routes';
import { trackViewProductDetails } from '../../firebase/analytics';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    sendInquiryInProgress,
    sendInquiryError,
    listingConfig: listingConfigProp,
    history,
    callSetInitialValues,
    onSendInquiry,
    config,
    routeConfiguration,
    inquiryModalOpenForListingId,
    reviews,
    authorListedItems,
    makeAnOfferInProgress,
    makeAnOfferError,
  } = props;

  const routes = useRouteConfiguration();

  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    inquiryModalOpenForListingId === rawParams.id
  );
  const modalRef = useRef();
  const dispatch = useDispatch();

  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  useEffect(() => {
    if (currentListing?.id && currentListing?.attributes?.title) {
      trackViewProductDetails({
        productId: currentListing.id.uuid,
        productName: currentListing.attributes.title,
      });
    }
  }, [currentListing?.id, currentListing?.attributes?.title]);

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const isClosed = currentListing.id && currentListing.attributes.state === LISTING_STATE_CLOSED;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    return <NotFoundPage />;
  }
  if (showListingError) {
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }
  if (!currentListing.id) {
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const { images } = currentListing;

  const {
    description = '',
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const { category, subCategory, size, type } = publicData;

  const likes = getNumberOfLikes(currentListing);

  const richTitle = richText(title, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
    longWordClass: css.longWord,
  });

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });

  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const { marketplaceName } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );

  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const isOutOfStock = currentStock === 0;

  const onSubmit = handleSubmit({
    ...commonParams,
    history,
    params,
    currentUser,
    getListing,
    callSetInitialValues,
    routes,
  });

  const onBuyNow = () => {
    onSubmit({
      quantity: 1,
    });
  };

  const handleMakeAnOffer = async values => {
    const { totalPrice } = values;
    const transactionId = await dispatch(
      makeAnOffer({
        listingId: currentListing.id,
        quantity: 1,
        totalPrice,
      })
    );

    if (transactionId) {
      modalRef.current.onClose();
      history.push(
        createResourceLocatorString(
          'OrderDetailsPageRedirect',
          routeConfiguration,
          { id: transactionId.uuid },
          {}
        )
      );
    }
  };

  const onOpenMakeOfferPopup = () => {
    modalRef.current.onOpen();
  };

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        {/* {currentListing.id && isOwnListing ? (
          <ActionBarMaybe
            className={css.actionBarForProductLayout}
            isOwnListing={isOwnListing}
            listing={currentListing}
            editParams={{
              id: listingId.uuid,
              slug: listingSlug,
              type: listingPathParamType,
              tab: listingTab,
            }}
          />
        ) : null} */}
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            <SectionGallery
              images={images}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
            <div className={classNames(css.mobile, css.section)}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            </div>
            <div className={css.mobile}>
              <div className={classNames(css.sizeRow, css.section)}>
                <ListingSize size={size} />
                <span className={css.iconsWrapper}>
                  <ListingLikes likes={likes} listingId={listingId} currentUser={currentUser} />
                  <ListingShareButton />
                </span>
              </div>
            </div>
            <ListingPrice price={price} className={classNames(css.mobile, css.section)} />
            <SectionTextMaybe
              text={description}
              showAsIngress
              className={classNames(css.mobile, css.section)}
              heading={<FormattedMessage id="ListingPage.descriptionSectionTitle" ignoreTag />}
            />
            <SectionDetailsMaybe
              className={classNames(css.mobile, css.section)}
              category={category}
              subCategory={subCategory}
              listingConfig={listingConfig}
              publicData={publicData}
              metadata={metadata}
              type={type}
            />

            <SectionShipping className={classNames(css.mobile, css.section)} />

            <SectionSecurePaymentOptions className={classNames(css.mobile, css.section)} />

            <hr className={css.mobile} />
            <SectionBuyerProtection className={classNames(css.mobile, css.section)} />

            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
              authorListedItems={authorListedItems}
              reviews={reviews}
            />
          </div>
          <div className={css.orderColumnForProductLayout}>
            <div className={css.section}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
              </H4>
            </div>
            <div className={css.desktop}>
              <div className={classNames(css.sizeRow, css.section)}>
                <ListingSize size={size} />
                <span className={css.iconsWrapper}>
                  <ListingLikes likes={likes} listingId={listingId} currentUser={currentUser} />
                  <ListingShareButton />
                </span>
              </div>
            </div>
            <ListingPrice price={price} className={classNames(css.desktop, css.section)} />
            <div className={classNames(css.desktop, css.section)}>
              <ActionButtons
                onBuyNow={onBuyNow}
                isOutOfStock={isOutOfStock}
                isClosed={isClosed}
                onMakeAnOffer={onOpenMakeOfferPopup}
                isOwnListing={isOwnListing}
              />
            </div>

            <hr className={classNames(css.desktop, css.section)} />
            <SectionBuyerProtection className={classNames(css.desktop, css.section)} />
            <SectionTextMaybe
              text={description}
              showAsIngress
              className={classNames(css.desktop, css.section)}
              heading={<FormattedMessage id="ListingPage.descriptionSectionTitle" ignoreTag />}
            />
            <SectionDetailsMaybe
              className={classNames(css.desktop, css.section)}
              category={category}
              subCategory={subCategory}
              listingConfig={listingConfig}
              publicData={publicData}
              metadata={metadata}
              type={type}
            />
            <SectionShipping className={classNames(css.desktop, css.section)} />
            <SectionSecurePaymentOptions className={classNames(css.desktop, css.section)} />
          </div>
        </div>
        <div className={css.actionButtonWrapper}>
          <ActionButtons
            onBuyNow={onBuyNow}
            isOutOfStock={isOutOfStock}
            isClosed={isClosed}
            onMakeAnOffer={onOpenMakeOfferPopup}
            isOwnListing={isOwnListing}
          />
        </div>
        <MakeAnOfferPopup
          ref={modalRef}
          onManageDisableScrolling={onManageDisableScrolling}
          handleMakeAnOffer={handleMakeAnOffer}
          makeAnOfferInProgress={makeAnOfferInProgress}
          makeAnOfferError={makeAnOfferError}
        />
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  sendInquiryError: null,
  listingConfig: null,
  authorListedItems: 0,
  makeAnOfferInProgress: false,
  makeAnOfferError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  listingConfig: object,
  authorListedItems: number,
  makeAnOfferInProgress: bool,
  makeAnOfferError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    authorListedItems,
    makeAnOfferInProgress,
    makeAnOfferError,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? ensureListing(listings[0]) : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? ensureOwnListing(listings[0]) : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    authorListedItems,
    makeAnOfferInProgress,
    makeAnOfferError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValuesFn, values, saveToSessionStorage) =>
    dispatch(setInitialValuesFn(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
